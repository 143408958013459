import React from 'react'
import '../../../src/assets/index.css';
import { Container, Row, Form, InputGroup } from 'react-bootstrap'
import logo from '../../../src/assets/images/logo.png';
import iconfb from '../../../src/assets/images/Icon awesome-facebook.png'
import iconemail from '../../../src/assets/images/Icon material-emails.png'

function FooterDark() {
  return (
    <Container fluid className='landingPage-footers landingPage-footer-bg'>
      <footer class="w-100 py-4 flex-shrink-0">
        <div class=" py-4">
          <div class="row gy-4 gx-5">
            <div class="col-lg-8 col-md-6">
              <img src={logo} className="footer-img-fluids" alt="logo" />
              <p className='landingPage-footers p-24' >Research and Development Center</p>
              <p className='landingPage-footers p-18' >101 V. Luna Ave. Extensions,<br /> Sikatuna Village, Quezon City,<br /> Philippines 1101</p>
            </div>

            <div class="pt-3 col-lg-4 col-md-5 ">
              <p><span className='landingPage-footers p-28'> Follow Us</span><br /><br />
                <img src={iconfb} className='landingPage-footer img-padding' alt="logofb" />
                <span className='landingPage-footers p-18'>https://facebook.com/dizione</span><br />
              </p>
              <Form.Label> <span className='landingPage-footers p-28'> Contact Us </span> </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control placeholder="Email" />
                <InputGroup.Text><img src={iconemail} className='icon' alt="logomail" /></InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>

        <Row className='border-top'>
          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 landingPage-footer textAlign">
            <ul className="list-unstyled d-flex footer-sections">
              <li className='section-footer-text' >
                <a href="/">
                  <p className=''>Home</p>
                </a>
              </li>
              <li className='section-footer-text'>
                <a href="/about">
                  <p className=''>About Us</p>
                </a>
              </li>
              <li className='section-footer-text'>
                <a href="/osiris">
                  <p className=''>Osiris</p>
                </a>
              </li>
              <li className='section-footer-text'>
                <a href="/ontraq">
                  <p className=''>Ontraq</p>
                </a>
              </li>
              <li className='section-footer-text'>
                <a href="/parentline">
                  <p className=''>Parentline</p>
                </a>
              </li >
              <li className='section-footer-text'>
                <a href="/support">
                  <p className=''>Support</p>
                </a>
              </li>
            </ul>
            <p className='col-lg-4 landingPage-footers p-18'>Copyright 2022 DiziOne All Rights Reserved.</p>
          </div>
        </Row>
      </footer>
    </Container>
  )
}

export default FooterDark