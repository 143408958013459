import React from 'react'
import { Container, Col, Row, Form, Button, Nav, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import logo from '../assets/images/logo.png';

function Support() {

  return (
    <Container fluid className='container-table'>
      <div className='support-bg-image'>

        <Navbar expand="lg" variant="light" className="navbar navbar-expand-lg navbar-light sticky-top bg-black header-nav">
          <div className="container-fluid">
            <div className="d-flex flex-grow-1">
              <Navbar.Brand className="d-none d-lg-inline-block" href="/">
                <img src={logo} alt="logo" className="img-icon-fluid" />
              </Navbar.Brand>
              <Navbar.Brand className="navbar-brand-two mx-auto d-lg-none d-inline-block">
                <img src={logo} alt="logo" className="img-icon-fluids" />
              </Navbar.Brand>
              <div className="w-100 text-right">
                <Navbar.Toggle aria-controls="myNavbar" />
              </div>
            </div>
            <Navbar.Collapse id="myNavbar" className="flex-grow-1 text-center home-navbar">
              <Nav className="ms-auto flex-nowrap">
                <Nav.Item>
                  <Nav.Link href="/" className="nav-link-white m-3 menu-item">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/about" className="nav-link-white m-3 menu-item">About Us</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/osiris" className="nav-link-white m-3 menu-item">Osiris</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/ontraq" className="nav-link-white m-3 menu-item">Ontraq</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/parentline" className="nav-link-white m-3 menu-item">Parentline</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/support" className="nav-link-white m-3 menu-item active">Support</Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>

        <section className='table-container'>
          <Container fluid>
            <Row className='row-part'>
              <Col xs={12} md={8}>
                <h1 className='section-support-h1' >GET IN TOUCH,</h1>
                <p className="col-md-7 section-support-p">Have a project in mind? or just simply say HI!</p>
                <ul className="list-unstyled pl-md-5 mb-5">
                  <li className="d-flex mb-2 section-supportsub-p">
                    Feel like talking?
                  </li>
                  <li className="d-flex section-supportsubs-p">
                    0927 123 456 789
                  </li>
                </ul>

                <div className="mb-5 ml-md-1">
                  <li className="d-flex mb-4 section-supportsub-p">We appreciate a visit,</li>
                  <div className="d-flex gap-4 form-icons">
                    <a href="https://facebook.com/headstartInc">
                      <FontAwesomeIcon icon={faFacebookF} className="font-awesome-icon" />
                    </a>
                    <a href="https://facebook.com/headstartInc" >
                      <FontAwesomeIcon icon={faInstagram} className="font-awesome-icon" />
                    </a>
                    <a href="https://facebook.com/headstartInc" >
                      <FontAwesomeIcon icon={faTwitter} className="font-awesome-icon" />
                    </a>
                    <a href="https://facebook.com/headstartInc" >
                      <FontAwesomeIcon icon={faLinkedin} className="font-awesome-icon" />
                    </a>
                  </div>
                </div>
              </Col>


              <Col xs={12} md={4} className="form-input">
                <Form>
                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control type="text" name="name" placeholder="Name" className='support-form' />
                    </Form.Group>
                  </Col>

                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control type="text" name="email" placeholder="Email" className='support-form' />
                    </Form.Group>
                  </Col>

                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control type="text" name="subject" placeholder="Subject" className='support-form' />
                    </Form.Group>
                  </Col>

                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control as="textarea" name="message" placeholder="Message" rows={7} className='support-textarea' />
                    </Form.Group>
                  </Col>

                  <Col md={9} xs={12}>
                    <Button variant="primary" className="mb-4 rounded-0 py-3 px-4 support-btn" type="submit">
                      Send
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>

      </div>
    </Container>
  )
}

export default Support