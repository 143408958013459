import React from 'react'
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap'
import FooterDark from '../../src/views/components/FooterDark'


import logo from '../assets/images/logo.png';
import parentline from '../assets/images/parentline.png'
import parentline2 from '../assets/images/parentline2.png'
import parentlineicon1 from '../assets/images/parentlineicon1.svg';
import parentlineicon2 from '../assets/images/parentlineicon2.svg';
import parentlineicon3 from '../assets/images/parentlineicon3.svg';
import parentlineicon4 from '../assets/images/parentlineicon4.svg';
import parentlineicon5 from '../assets/images/parentlineicon5.svg';
import mobileparentline1 from '../assets/images/mobileparentline1.png';
import mobileparentline2 from '../assets/images/mobileparentline2.png';
import mobileparentline3 from '../assets/images/mobileparentline3.png';
import mobileparentline4 from '../assets/images/mobileparentline4.png';


function Parentline() {


  return (
    <Container fluid className='container-table'>

      <Navbar expand="lg" variant="light" className="navbar navbar-expand-lg navbar-light sticky-top bg-black header-nav">
        <div className="container-fluid">
          <div className="d-flex flex-grow-1">
            <Navbar.Brand className="d-none d-lg-inline-block" href="/">
              <img src={logo} alt="logo" className="img-icon-fluid" />
            </Navbar.Brand>
            <Navbar.Brand className="navbar-brand-two mx-auto d-lg-none d-inline-block">
              <img src={logo} alt="logo" className="img-icon-fluids" />
            </Navbar.Brand>
            <div className="w-100 text-right">
              <Navbar.Toggle aria-controls="myNavbar" />
            </div>
          </div>
          <Navbar.Collapse id="myNavbar" className="flex-grow-1 text-center home-navbar">
            <Nav className="ms-auto flex-nowrap">
              <Nav.Item>
                <Nav.Link href="/" className="nav-link-white m-3 menu-item">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/about" className="nav-link-white m-3 menu-item">About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/osiris" className="nav-link-white m-3 menu-item">Osiris</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/ontraq" className="nav-link-white m-3 menu-item">Ontraq</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/parentline" className="nav-link-white m-3 menu-item active">Parentline</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/support" className="nav-link-white m-3 menu-item">Support</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className='parentline-bg-image' >
        <Container fluid className='container-table'>
          <div className="section-osiris-containers">
            <div className="col-md-8">
              <img src={parentline} className="pt-4 pb-3 img-fluid " style={{ maxWidth: '42%' }} alt="test2" /><br />
              <img src={parentline2} className="pt-3 pb-5 img-fluid" style={{ maxWidth: '75%' }} alt="test2" />

              <h1 className="pb-3 parentline-header-h1">Makes it easy to communicate with parents</h1>
              <p className="pb-5 parentline-header-p">Enables schools to inform parents of important student information and urgent advisories through text messaging and push notification</p>
            </div>
          </div>
        </Container>

        <section>
          <div className="lines-osiris-container">
            <div className="line-osiris"></div>
            <div className="system-osiris-text">MOBILE</div>
          </div>
        </section>

        <Container fluid>
          <section className='pt-5'>
            <Row className="align-items-center">
              <Col md={6} className="col-md-6 d-flex justify-content-center">
                <img src={mobileparentline1} className="img-fluid img-mobile" alt="test2" />
              </Col>

              <Col md={6} className="pb-md-0 pb-5 ">
                <h1 className='col-md-8 offset-md-1 osiris-h1'>Announcement</h1>
                <p className='col-md-8 offset-md-1 osiris-p'>A functionality where users can view the school announcement</p>
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section className='pt-5'>
            <Row className="align-items-center">
              <Col md={6} className="order-md-1 order-2 pb-md-0 pb-5">
                <h1 className='col-md-6 offset-md-5 osiris-h1'>Ontraq</h1>
                <p className='col-md-8 offset-md-5 osiris-p'>A functionality where enrolled Ontarq users can monitor the time-in and time-out of the students</p>
              </Col>

              <Col md={6} className=" d-flex justify-content-center order-md-2 order-1">
                <img src={mobileparentline2} className="img-fluid img-mobile" alt="test" />
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section className='pt-5'>
            <Row className="align-items-center">
              <Col md={6} className="ml-md-auto d-flex justify-content-center">
                <img src={mobileparentline3} className="img-fluid img-mobile" alt="test2" />
              </Col>

              <Col md={6} className="pb-md-0 pb-5">
                <h1 className='col-md-5 osiris-h1'>E-Wallet</h1>
                <p className='col-md-8 osiris-p'>A functionality where users can monitor the balance and transaction history of the e-wallet</p>
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section className='pt-5'>
            <Row pd={5} className="align-items-center">
              <Col md={6} className="order-md-1 order-2 pb-md-0 pb-5">
                <h1 className='col-md-6 offset-md-5 osiris-h1'>E-Wallet Scanner</h1>
                <p className='col-md-8 offset-md-5 osiris-p'>A functionality where users can scan the QR of a specific student to make a transaction</p>
              </Col>

              <Col md={6} className=" d-flex justify-content-center order-md-2 order-1 pb-md-0 pb-3">
                <img src={mobileparentline4} className="img-fluid img-mobile" alt="test" />
              </Col>
            </Row>
          </section>
        </Container>
      </div>

      <section className='section-parentline-background table-container'>
        <Container fluid>
          <h1 className='pb-5 pt-5 section-table-h1'>Parentline Mobile Application</h1>
          <Row className='pb-5' >
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon1} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Add Student</h1>
                <Row>
                  <p className='section-table-p'>• Add student manually</p>
                  <p className='section-table-p'>• Add student by QR code</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon2} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Announcements</h1>
                <Row>
                  <p className='section-table-p'>• View announcements sent by the school</p>
                  <p className='section-table-p'>• Preview date and time received, and title/subject of the message</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon3} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>LMS</h1>
                <h5 className='section-table-p parentline-h5'>**If enrolled in TekTeach LMS</h5>
                <Row>
                  <p className='section-table-p'>• View performance of the student in the class</p>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className='pb-5' >
            <Col md={4} >
              <img src={parentlineicon4} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Attendance</h1>
                <h5 className='section-table-p parentline-h5'>**If enrolled in Ontraq</h5>
                <Row>
                  <p className='section-table-p'>• View date, time, and venue where the student entered and exit</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} >
              <img src={parentlineicon5} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>E-Wallet</h1>
                <h5 className='section-table-p parentline-h5'>**If enrolled in Ontraq</h5>
                <Row>
                  <p className='section-table-p'>• View the remaining balance</p>
                  <p className='section-table-p'>• View all the transactions history</p>
                </Row>
              </Row>
            </Col>
            <Col md={3} className='mx-auto' />
          </Row>
        </Container>
      </section>

      <section className='section-parentline-background table-container'>
        <Container fluid>
          <h1 className='pb-5 pt-5 section-table-h1' >Parentline Web Portal</h1>
          <Row className='pb-5' >
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon1} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Students Module</h1>
                <Row>
                  <p className='section-table-p'>• View complete details of a specific student</p>
                  <p className='section-table-p'>• Add and edit student details</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon2} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Parents Module</h1>
                <Row>
                  <p className='section-table-p'>• View complete details of a specific parent</p>
                  <p className='section-table-p'>• Add and edit parent details</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon3} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Attendance Module</h1>
                <Row>
                  <p className='section-table-p'>• Add and edit venue details</p>
                  <p className='section-table-p'>• Add and edit devices located on a specific venue</p>
                  <p className='section-table-p'>• Monitor attendance of a student/ employee (w/ date, time, and venue)</p>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className='pb-5' >
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon4} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Messages Module</h1>
                <Row>
                  <p className='section-table-p'>• Add and edit group details</p>
                  <p className='section-table-p'>• Create announcement, and send to a specific group or individually </p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon5} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Reports Module</h1>
                <Row>
                  <p className='section-table-p'>• Sort by report type and date, ready to print attendance</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={parentlineicon5} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='section-table-h3'>Reports Module</h1>
                <Row>
                  <p className='section-table-p'>• View all failed and successful transactions</p>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <FooterDark />
    </Container>
  )
}

export default Parentline