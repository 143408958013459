import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import Header from "../components/header/Header";
import Home from '../views//Home'
import About from "../views/About";
import Support from "../views/Support";
import Osiris from "../views/Osiris";
import Ontraq from "../views/Ontraq";
import Parentline from "../views/Parentline";

export default function Routing() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = window.localStorage.getItem("token")
    console.log({token})
    if(token != null){
      setIsLoggedIn(true)
      setLoading(false)
    }
  }, [])


  return (
    <div className="content">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/header' element={<Header/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/osiris' element={<Osiris/>}/>
          <Route path='/ontraq' element={<Ontraq/>}/>
          <Route path='/parentline' element={<Parentline/>}/>
          <Route path='/support' element={<Support/>}/>

          {/* {
            !loading && isLoggedIn ? (
              <>
                <Route path='/home' element={<Home/>}/>
              </>
            ) : (
              <>
                <Route path='/home' element={<Home/>}/>
              </>
            )
          } */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}