import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import FooterLight from '../../src/views/components/FooterLight';

import logo from '../assets/images/logo.png';
import headstartlogo from '../assets/images/headstartlogo.png';
import test from '../assets/images/test.png';
import test2 from '../assets/images/test2.png';

function About() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container fluid className='container-table'>

      <Navbar expand="lg" variant="light" className={`navbar navbar-expand-lg navbar-light sticky-top bg-black ${isMobileView ? 'fixed-top' : ''} header-nav`}>
        <div className="container-fluid">
          <div className="d-flex flex-grow-1">
            <Navbar.Brand className="d-none d-lg-inline-block" href="/">
              <img src={logo} alt="logo" className="img-icon-fluid" />
            </Navbar.Brand>
            <Navbar.Brand className="navbar-brand-two mx-auto d-lg-none d-inline-block">
              <img src={logo} alt="logo" className="img-icon-fluids" />
            </Navbar.Brand>
            <div className="w-100 text-right">
              <Navbar.Toggle aria-controls="myNavbar" />
            </div>
          </div>
          <Navbar.Collapse id="myNavbar" className="flex-grow-1 text-center home-navbar">
            <Nav className="ms-auto flex-nowrap">
              <Nav.Item>
                <Nav.Link href="/" className="nav-link-white m-3 menu-item">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/about" className="nav-link-white m-3 menu-item active">About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/osiris" className="nav-link-white m-3 menu-item">Osiris</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/ontraq" className="nav-link-white m-3 menu-item">Ontraq</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/parentline" className="nav-link-white m-3 menu-item">Parentline</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/support" className="nav-link-white m-3 menu-item">Support</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className='landingPage-bg-image'>
        <Container fluid>
          <Row className='section-containers'>
            <Col xs={12} md={8}>
              <div className='table-container'>
                <h1 className='section-title display-1' >WE ARE</h1>
                <h1 className='home-title-p display-2' >Dizi<span className='sections-title'>One </span> </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className='bg-black'>
        <div className="lines-container">
          <div className="line"></div>
          <div className="system-text">ABOUT US</div>
        </div>
      </section>

      <Container fluid className='bg-black'>
        <section className='px-0 section-container container-table overflow-hidden'>
          <Row className="align-items-center">
            <Col md={6} className="ml-md-auto d-flex justify-content-center">
              <img src={logo} className="pt-5 pb-3 img-fluid icon-logo" style={{ maxWidth: '70%', height: 'auto' }} alt="test2" />
            </Col>

            <Col md={6} className="pb-md-0 pb-5">
              <h1 className='col-md-10 pt-3 display-4 section-h1'>THE ORGANIZATION</h1>
              <p className='col-md-8 display-3 section-p'>DiziOne works towards
                developing strategic partnership with clients by shaping customer interfaces. We assist businesses in establishing and managing
                their internal and external services through it systems to help them achieve competitive advantage.</p>
            </Col>
          </Row>
        </section>
      </Container>

      <Container fluid className=" px-0 container-table overflow-hidden">
        <section className=' px-0 container-table overflow-hidden'>
          <Row className="align-items-center bg-black">
            <Col md={6} className="order-md-1 order-2 pb-5">
              <h1 className="col-md-6 pt-3 pb-3 about-h1 offset-md-3">Vision</h1>
              <p className="col-md-6 about-p offset-md-3">
                Aiming To Be The Leading School Management System Provider, In Shaping Customer Interfaces Across The Philippines
              </p>
            </Col>

            <Col md={6} className="ml-md-auto d-flex justify-content-center order-md-2 order-1">
              <img src={test} className="img-fluids" alt="test" />
            </Col>
          </Row>
        </section>
      </Container>

      <Container fluid className=" px-0 container-table overflow-hidden">
        <Row className="align-items-center bg-black">
          <Col md={6} className="ml-md-auto d-flex justify-content-center">
            <img src={test2} className="img-fluids" alt="test2" />
          </Col>

          <Col md={6} className="pb-md-0 pb-5">
            <h1 className='col-md-8 pt-3 pb-3 offset-md-2 about-h1'>Mission</h1>
            <p className='col-md-6 offset-md-2 about-p'>To Provide Effortless Transactional Experience,
              That Will Bridge The Gap Between Schools, Teachers, Students, and Parents</p>
          </Col>
        </Row>
      </Container>

      <FooterLight />
    </Container>
  );
}

export default About;