import React from 'react'
import { useState, useEffect } from 'react';
import { Container, Navbar, Nav, Button, Row, Col } from 'react-bootstrap'
import FooterLight from '../../src/views/components/FooterLight'

import logo from '../assets/images/logo.png';
import sample from '../assets/images/sample.png'
import sample2 from '../assets/images/sample2.png'
import icon1 from '../assets/images/icon1.png'
import icon2 from '../assets/images/icon2.png'
import icon3 from '../assets/images/icon3.png'
import maps from '../assets/images/maps.png'



function Home() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container fluid className='container-table'>

      <Navbar expand="lg" variant="light" className={`navbar navbar-expand-lg navbar-light sticky-top bg-black ${isMobileView ? 'fixed-top' : ''} header-nav`}>
        <div className="container-fluid">
          <div className="d-flex flex-grow-1">
            <Navbar.Brand className="d-none d-lg-inline-block" href="/">
              <img src={logo} alt="logo" className="img-icon-fluid" />
            </Navbar.Brand>
            <Navbar.Brand className="navbar-brand-two mx-auto d-lg-none d-inline-block">
              <img src={logo} alt="logo" className="img-icon-fluids" />
            </Navbar.Brand>
            <div className="w-100 text-right">
              <Navbar.Toggle aria-controls="myNavbar" />
            </div>
          </div>
          <Navbar.Collapse id="myNavbar" className="flex-grow-1 text-center home-navbar">
            <Nav className="ms-auto flex-nowrap">
              <Nav.Item>
                <Nav.Link href="/" className="nav-link-white m-3 menu-item active">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/about" className="nav-link-white m-3 menu-item">About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/osiris" className="nav-link-white m-3 menu-item">Osiris</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/ontraq" className="nav-link-white m-3 menu-item">Ontraq</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/parentline" className="nav-link-white m-3 menu-item">Parentline</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/support" className="nav-link-white m-3 menu-item">Support</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className='home-bg-image' >
        <Container fluid>
          <Row className='section-containers'>
            <Col xs={12} md={8}>
              <div className='table-containers'>
                <h1 className='home-title-p display-2' >Dizi<span className='sections-title'>One </span> </h1>
                <p className="home-subtitle display-4">Manage.</p>
                <p className="home-subtitle display-4">Secure.</p>
                <p className="home-subtitle display-4">Communicate.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className='bg-black'>
        <div className="lines-first-container">
          <div className="line"></div>
          <div className="system-text">ABOUT US</div>
        </div>
      </section>

      <Container fluid className='bg-black'>
        <section className="pt-5 pb-5 bg-black">
          <Row className="align-items-center">
            <Col md={7} className="ml-md-auto d-flex justify-content-center">
              <img src={sample} className="pb-5 pt-5 img-fluid" style={{ maxWidth: '65%' }} alt="test2" />
            </Col>

            <Col md={5} className="text-center text-md-left pb-md-0 pb-5">
              <div className="d-flex flex-column align-items-center align-items-md-start">
                <img src={logo} className="img-fluid mb-3" style={{ width: '40%' }} alt="logo" />
                <p className='col-md-10 home-section-p'>Established in 2006, DiziOne
                  assists business partners in establishing and managing their internal and external I.T systems and services.</p>
                <Button href='/about' className='home-btn mx-auto mx-md-0'>Learn More</Button>
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      <div className='home-bg-image2' />

      <section className='bg-black'>
        <div className="lines-second-container">
          <div className="line"></div>
          <div className="system-text">OUR SYSTEM</div>
        </div>
      </section>

      <Container fluid className='bg-black'>
        <section classname="pt-5 pb-5">
          <Row pd={5} className="align-items-center">
            <Col md={6} className="order-md-1 order-2 pb-5 pt-5">
              <div className="image-container">
                <a href='/osiris'>
                  <h1 className='pt-5 section-icon'>
                    <span style={{ marginRight: '8px' }}>01</span>
                    <img src={icon1} className="img-fluid" style={{ width: '30%' }} alt="icon1" />
                  </h1>
                </a>
              </div>
              <p className='pt-2 pb-3 col-md-8 section-left-p'>A School Management System that provides excellent student services.</p>

              <div className="image-container">
                <a href="/ontraq">
                  <h1 className='section-icon'>
                    <span style={{ marginRight: '8px' }}>02</span>
                    <img src={icon2} className="img-fluid" style={{ maxWidth: '30%', width: '100%' }} alt="icon1" />
                  </h1>
                </a>
              </div>
              <p className='pt-2 pb-3 col-md-8 section-left-p'>An intelligent I.D System that automatically validates and ensures the safety of the students and school personnel.</p>

              <div className="image-container">
                <a href='/parentline'>
                  <h1 className='section-icon'>
                    <span style={{ marginRight: '8px' }}>03</span>
                    <img src={icon3} className="img-fluid" style={{ width: '30%' }} alt="icon1" />
                  </h1>
                </a>
              </div>
              <p className='pt-2 pb-3 col-md-8 section-left-p'>A Communication System that enables schools to inform parents on important and urgent advisories.</p>
            </Col>

            <Col md={6} className="d-flex justify-content-center order-md-2 order-1">
              <img src={sample2} className="pt-5 img-fluid" style={{ maxWidth: '80%' }} alt="test" />
            </Col>
          </Row>
        </section>
      </Container>

      <div className="lines-map-container">
        <div className="line"></div>
        <div className="system-text">MAP</div>
      </div>

      <Container fluid className='bg-black'>
        <section className="pt-5 pb-5">
          <Row className="align-items-center">
            <Col md={6} className='pt-5'>
              <h1 className="section-icons">Our Partner Schools</h1>
            </Col>
          </Row>
          <Col md={{ span: 6, offset: 4 }}>
            <img src={maps} className="pb-5 img-fluid image-map" style={{ maxWidth: '100%' }} alt="sample2" />
          </Col>
        </section>
      </Container>


      <FooterLight />
    </Container>
  )
}

export default Home;